import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, Gallery, Thumb, TrailguidePlugin } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`This weekend the first race of the Norwegien 80/20 enduro series was held in
Nesbyen, Hallingdal in Norway on perfect dry and dusty trails in a fantastic
weather. The event was also an EWS qualifier, so you could find happy riders
from Norway, Sweden, Australia, UK, Denmark and more.`}</p>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-25.jpg" mdxType="Image" />
    <h4>{`Bård Stærkebye Pettersen flying through the foliage on stage 5`}</h4>
    <h2>{`High speed, dust, and crashes`}</h2>
    <h4>{`The trails were bone dry the speed was high`}</h4>
    <Image src="nesbyen_enduro_2018/nesbyen2018-33.jpg" mdxType="Image" />
    <h4>{`Fredrik Tøien on stage 2`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-4.jpg" mdxType="Image" />
    <h4>{`Michael Cook speeding down stage 2`}</h4>
    <br />
    <p>{`At some point there was a pileup of crashes in one of the tricky sections of
stage, but must of the riders got back up and finished the race.`}</p>
    <Image src="nesbyen_enduro_2018/nesbyen2018-2.jpg" mdxType="Image" />
    <h4>{`Scott Wilson trying to catch his breath after hitting the ground face first`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-3.jpg" mdxType="Image" />
    <h4>{`The dust has not yet settled after multiple crashes`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-6.jpg" mdxType="Image" />
    <h4>{`Emil Carlson on stage 2`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-8.jpg" mdxType="Image" />
    <h4>{`Anton Karlsson making it safely through the pile-up zone where multiple riders crashed on top of each other`}</h4>
    <h2>{`Interactive map`}</h2>
    <h4>{`An event map was made for the Nesbyen Enduro race and tested out for the first time`}</h4>
    <Image src="nesbyen_enduro_2018/nesbyen2018-14.jpg" mdxType="Image" />
    <h4>{`The riders had easy access to an interactive map through a QR code on the bike frames`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/trailguide.jpg" mdxType="Image" />
    <h4>{`The tracks were released the night before training day and riders were able to use their smartphones to navigate and find the stages`}</h4>
    <br />
    <p>{`The stages on Nesbyen Enduro 2018: `}<a parentName="p" {...{
        "href": "https://trailguide.net/x1a"
      }}>{`trailguide.net/x1a`}</a></p>
    <TrailguidePlugin content="/x1a" mdxType="TrailguidePlugin" />
    <h2>{`Early Summer`}</h2>
    <h4>{`Summer came early and in full force to Hallingdal this year`}</h4>
    <Image src="nesbyen_enduro_2018/nesbyen2018-16.jpg" mdxType="Image" />
    <h4>{`More than 2000 vertical meters had to be pedaled in 27+ degrees, sun, and no wind`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-12.jpg" mdxType="Image" />
    <h4>{`These guys did not mind the summer weather though`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-15.jpg" mdxType="Image" />
    <h4>{`Hilde Strædet, the winner of the womens category on the last stage`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-21.jpg" mdxType="Image" />
    <h4>{`Kristoffer Apeland navigating the last part of stage 5`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-26.jpg" mdxType="Image" />
    <h4>{`Jon Bokrantz hurdling down stage 5`}</h4>
    <br />
    <h2>{`Winners and runner-ups`}</h2>
    <Image src="nesbyen_enduro_2018/nesbyen2018-31.jpg" mdxType="Image" />
    <h4>{`The winner of Nesbyen Enduro 2018, Zakarias Blom Johansen`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-27.jpg" mdxType="Image" />
    <h4>{`Dillon Santos from Salinas, USA, finished 3rd`}</h4>
    <br />
    <Image src="nesbyen_enduro_2018/nesbyen2018-28.jpg" mdxType="Image" />
    <h4>{`Espen Bergli-Johnsen, finished 4th, right behind the pros`}</h4>
    <h2>{`Picture Gallery - Nesbyen Enduro 2018`}</h2>
    <br />
    <Gallery mdxType="Gallery">
  <Thumb src="nesbyen_enduro_2018/bib-1.jpg" mdxType="Thumb">
    1 Anette Røssum Bastnes on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-2.jpg" mdxType="Thumb">
    2 Vera Leivsdottir on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-3.jpg" mdxType="Thumb">3 Ingun Ståhl on stage 5</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-5.jpg" mdxType="Thumb">
    5 Alice Grindheim on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-6.jpg" mdxType="Thumb">
    6 Berglind Adalsteindottir on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-8.jpg" mdxType="Thumb">8 Mille Johnset on stage 5</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-9.jpg" mdxType="Thumb">
    9 Mari Guton Olsen on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-22.jpg" mdxType="Thumb">22 Michael Lund on stage 5</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-23.jpg" mdxType="Thumb">
    23 Rasmus Larsson on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-32.jpg" mdxType="Thumb">
    32 Campbell Stannard on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-350.jpg" mdxType="Thumb">
    35 Scott Wilson on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-38.jpg" mdxType="Thumb">
    38 Joffrey Thevenon on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-42.jpg" mdxType="Thumb">
    42 Richard Wessberg on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-43.jpg" mdxType="Thumb">43 Ola Ranes on stage 3</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-47.jpg" mdxType="Thumb">
    47 Anders Hereid on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-53.jpg" mdxType="Thumb">53 Anders Disen on stage 5</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-63.jpg" mdxType="Thumb">
    63 Eirik Lind Hånes on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-64.jpg" mdxType="Thumb">64 Øyvind Sætra on stage 2</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-69.jpg" mdxType="Thumb">
    69 Ole André Tveit on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-71.jpg" mdxType="Thumb">
    71 Fredrik Tøien on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-73.jpg" mdxType="Thumb">
    73 Harald Brekke on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-74.jpg" mdxType="Thumb">
    74 Sigmund Horrig on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-76.jpg" mdxType="Thumb">76 Gard Brovold on stage 3</Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-79.jpg" mdxType="Thumb">
    79 Björn Larsson on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-90.jpg" mdxType="Thumb">
    90 Carl Blomqvist on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-92.jpg" mdxType="Thumb">
    92 Timo Johansson on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-105.jpg" mdxType="Thumb">
    105 Shane Kroeger on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-109.jpg" mdxType="Thumb">
    109 Fredrik Lysakerrud on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-111.jpg" mdxType="Thumb">
    111 Christian Aronsen on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-112.jpg" mdxType="Thumb">
    112 Geir Hanssen on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-114.jpg" mdxType="Thumb">
    114 Arild Gravdal on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-115.jpg" mdxType="Thumb">
    115 Johan Schjerve on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-116.jpg" mdxType="Thumb">
    116 Daniel Sjölander on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-122.jpg" mdxType="Thumb">
    122 Simen Kalnæs Danielsen on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-124.jpg" mdxType="Thumb">
    124 Emil Carlson on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-127.jpg" mdxType="Thumb">
    127 Espen Bergli-Johnsen on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-128.jpg" mdxType="Thumb">
    128 Thorstein Gallefoss on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-129.jpg" mdxType="Thumb">
    129 Michael Cook on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-130.jpg" mdxType="Thumb">
    130 Adrian Hilde on stage 2
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-134.jpg" mdxType="Thumb">
    134 Zakarias Johansen on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-135.jpg" mdxType="Thumb">
    135 Robin Walner on stage 3
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-138.jpg" mdxType="Thumb">
    138 Simon Carlsson on stage 5
  </Thumb>
  <Thumb src="nesbyen_enduro_2018/bib-144.jpg" mdxType="Thumb">
    144 Bjørn Ballangrud on stage 2"
  </Thumb>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      